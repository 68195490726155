import { createContext, type ReactNode, useContext, useState } from 'react';

export type TableProviderProps = {
  children: ReactNode;
};

export type TableContextType = {
  state: {
    count: number;
    emptySelection: boolean;
    selectAllPages: boolean;
    selectedRows: string[];
    selectedRowsStringified: string;
  };
  actions: {
    clearSelection: () => void;
    setCount: (count: number) => void;
    setSelectAllPages: (state: boolean) => void;
    setSelection: (selection: string[]) => void;
  };
};

export const TableContext = createContext({} as TableContextType);

export const useTableContext = () => useContext(TableContext);

const TableProvider = ({ children }: TableProviderProps) => {
  const [count, setCount] = useState(0);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const actions = {
    clearSelection: () => {
      setSelectedRows([]);
      setCount(0);
    },
    setCount,
    setSelectAllPages,
    setSelection: (rows: string[]) => {
      setSelectedRows(rows);
      setCount(rows.length);
    }
  };

  const state = {
    count,
    emptySelection: !selectedRows.length && !selectAllPages,
    selectAllPages,
    selectedRows,
    selectedRowsStringified: selectAllPages
      ? 'select_all'
      : selectedRows.join(',')
  };

  return (
    <TableContext.Provider value={{ state, actions }}>
      {children}
    </TableContext.Provider>
  );
};

export default TableProvider;
