import { useMatch } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';

import server from '@/services/server';
import type { User } from '@/types/User';

const useAuth = () => {
  const dashboardUserTool = useMatch({ path: '/next/users/*' });

  const { data, error, isLoading, mutate } = useSWRImmutable(
    'user',
    async (path: string): Promise<User> =>
      server.get({
        path,
        queryParams: { tool: dashboardUserTool ? 'dashboard_user' : '' }
      })
  );

  const user = data?.data;
  const userType = user?.userType;

  return {
    error,
    isLoading,
    mutate,
    user: user,
    company: user?.selectedCompany,
    companyId: user?.selectedCompany?.id,
    customer: userType === 'customer',
    distributor: userType === 'distributor',
    admin: userType === 'admin',
    employee: userType === 'employee'
  };
};

export default useAuth;
