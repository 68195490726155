import { captureException } from '@sentry/react';
import type { AxiosError } from 'axios';
import axios from 'axios';

import { API_URL, SENTRY_ENABLED } from '@/environment';
import { t } from '@/translations/i18n';
import { camelToSnakeCase, snakeToCamelCase } from '@/utils/caseConverter';
import { isObject } from '@/utils/object';
import toast from '@/utils/toast';

import type { Delete, Get, Patch, Post, Put, Request } from './types';

const parse = (data: any) => {
  try {
    return snakeToCamelCase(
      typeof data === 'string' && data !== '' ? JSON.parse(data) : data
    );
  } catch (error) {
    return data;
  }
};

export const axiosInstance = axios.create({
  baseURL: API_URL,
  transformRequest: [data => JSON.stringify(camelToSnakeCase(data))],
  transformResponse: [data => parse(data)],
  withCredentials: true,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
});

export const axiosInstancePdf = axios.create({
  baseURL: API_URL,
  transformRequest: [data => JSON.stringify(camelToSnakeCase(data))],
  withCredentials: true,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  responseType: 'arraybuffer'
});

export const axiosInstanceBlob = axios.create({
  baseURL: API_URL,
  transformRequest: [data => JSON.stringify(camelToSnakeCase(data))],
  withCredentials: true,
  headers: { 'Content-Type': 'application/json', Accept: 'text/csv' },
  responseType: 'blob'
});

const server = {
  async get({
    baseURL,
    queryParams,
    path,
    version,
    pdf,
    pdf2,
    blob,
    blob2
  }: Get) {
    return this.request({
      baseURL,
      method: 'get',
      queryParams,
      path,
      version,
      pdf,
      pdf2,
      blob,
      blob2
    });
  },

  async post({
    baseURL,
    data,
    queryParams,
    path,
    version,
    pdf,
    pdf2,
    blob,
    blob2
  }: Post) {
    return this.request({
      baseURL,
      data,
      method: 'post',
      queryParams,
      path,
      version,
      pdf,
      pdf2,
      blob,
      blob2
    });
  },

  async patch({ baseURL, data, queryParams, path, version }: Patch) {
    return this.request({
      baseURL,
      data,
      method: 'patch',
      queryParams,
      path,
      version
    });
  },

  async put({ baseURL, data, queryParams, path, version }: Put) {
    return this.request({
      baseURL,
      data,
      method: 'put',
      queryParams,
      path,
      version
    });
  },

  async delete({ baseURL, queryParams, path, version }: Delete) {
    return this.request({
      baseURL,
      method: 'delete',
      queryParams,
      path,
      version
    });
  },

  async request({
    baseURL,
    data,
    method,
    queryParams: params,
    path,
    version,
    pdf = false,
    pdf2 = false,
    blob = false,
    blob2 = false
  }: Request) {
    try {
      const axios =
        pdf || pdf2
          ? axiosInstancePdf
          : blob || blob2
            ? axiosInstanceBlob
            : axiosInstance;

      // INFO: jezeli korzystamy z wersjonowania API i nie podano baseURL, to zmieniamy baseURL na namespace dash/api
      const modifiedBaseURL =
        version && !baseURL ? API_URL.replace('manage/', 'dash/api/') : null;

      const response = await axios.request({
        baseURL: modifiedBaseURL ?? baseURL,
        data,
        method,
        url: path,
        params,
        headers: { 'X-Dash-Api-Version': version }
      });

      return blob2 || pdf2 ? response : response.data;
    } catch (error) {
      const axiosError = error as AxiosError;

      if (SENTRY_ENABLED) {
        captureException(axiosError);
      }

      const data = axiosError.response?.data;

      if (
        (method === 'get' && !(data as any)?.errors?.base) ||
        axiosError.response?.status === 422
      ) {
        throw axiosError;
      }

      if (data) {
        let message;

        if (typeof data === 'string' && data.includes('<!DOCTYPE html>')) {
          message = t(['error.default']);
        } else {
          message = Object.entries(data).map(
            ([key, value]) =>
              `${t([`error.${key}`, 'error.default'])} ${
                Array.isArray(value)
                  ? value[0]
                  : isObject(value) && 'base' in value
                    ? value.base[0].replaceAll('<b>', '').replaceAll('</b>', '')
                    : value
              }`
          )[0];
        }

        toast({ type: 'error', message });
      } else {
        toast({
          type: 'error',
          message:
            'Something went wrong. Please, try again in a while or contact administrators.'
        });
      }

      throw axiosError;
    }
  }
};

export default server;
