import { Link as RouterLink } from 'react-router-dom';

import { RAILS_URL } from '@/environment';

export type LinkProps = {
  children: React.ReactNode;
  className?: string;
  redirectTo: string;
  relative?: boolean;
  newWindow?: boolean;
};

const Link = ({
  children,
  className,
  redirectTo,
  relative = false,
  newWindow = false
}: LinkProps) => {
  if (
    relative ||
    redirectTo.startsWith('next/') ||
    redirectTo.startsWith('/next/')
  )
    return (
      <RouterLink
        className={className}
        relative={relative ? 'path' : 'route'}
        to={redirectTo}
      >
        {children}
      </RouterLink>
    );

  return (
    <a
      className={className}
      href={new URL(redirectTo, RAILS_URL).href}
      target={newWindow ? '_blank' : '_self'}
    >
      {children}
    </a>
  );
};

export default Link;
